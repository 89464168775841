import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useMemo, useState } from 'react';

import EmailDialog from '../../../components/EmailDialog';
import {
  BofUserOverviewUserAndSessionsDocument,
  BulkEmailType,
  BulkEmailUnsubscribeType,
} from '../../../generated/backend/graphql';

type Props = {
  userId: string;
};

const L = locale.backoffice;

export default function UserOverview({ userId }: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data } = useQuery(BofUserOverviewUserAndSessionsDocument, {
    variables: {
      userId,
    },
  });

  const [user, home, latestSession] = useMemo(() => {
    const user = data?.user;
    const home = data?.user?.home ?? undefined;
    const sessions = data?.sessionInfos ?? [];

    const latestSession = sessions.reduce<Date | null>((acc, curr) => {
      const currDate = new Date(curr.updatedAt);

      if (!acc) {
        return currDate;
      }

      return currDate.getTime() - acc.getTime() > 0 ? currDate : acc;
    }, null);

    return [user, home, latestSession];
  }, [data]);

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} md={6}>
        <Stack spacing={2}>
          <Card>
            <CardContent>
              <Typography>{`${L.userOverview.created}: ${
                user?.createdAt
                  ? new Intl.DateTimeFormat('sv-SE', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                    }).format(new Date(user.createdAt))
                  : '-'
              }`}</Typography>
              <Typography>{`${L.userOverview.latestSession}: ${
                latestSession
                  ? new Intl.DateTimeFormat('sv-SE', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                    }).format(latestSession)
                  : '-'
              }`}</Typography>
            </CardContent>
          </Card>
          {home && (
            <Card>
              <CardContent>
                <EmailDialog
                  defaultType={BulkEmailType.UnfollowedHome}
                  recipients={
                    user?.id && user.email
                      ? [
                          {
                            id: user.id,
                            email: user.email,
                          },
                        ]
                      : []
                  }
                  userFilter={{}}
                  userFilterCount={-1}
                  open={dialogOpen}
                  onSent={() => {}}
                  onClose={() => setDialogOpen(false)}
                  options={[
                    {
                      type: BulkEmailType.UnfollowedHome,
                      unsubscribeType: BulkEmailUnsubscribeType.Marketing,
                      description: 'Följare avföjlde bostad',
                      message: '',
                      signatureType: 'withoutLogo',
                    },
                  ]}
                />
                <Box mb={1}>{L.userOverview.unfollowEmail}</Box>
                <Button
                  onClick={() => setDialogOpen(true)}
                  variant="contained"
                  color="secondary"
                  size="small"
                >
                  {L.userOverview.sendEmail}
                </Button>
              </CardContent>
            </Card>
          )}
        </Stack>
      </Grid>
      {home && (
        <Grid item sm={12} md={6}>
          <Card>
            <CardHeader title={L.userOverview.home} />
            <CardContent>
              <Typography>{`${L.streetAddress}: ${home.address?.streetAddress ?? '-'}`}</Typography>
              <Typography>{`${L.userOverview.agent}: ${home?.assignedAgent?.name ?? '-'}`}</Typography>
              <Typography>{`${L.userOverview.homeStatus}: ${home?.state ? L.enumerations.HomeState[home.state] : '-'}`}</Typography>
              <Typography>{`${L.userOverview.salesStatus}: ${home?.salesProcessStatus ? L.enumerations.SalesProcessStatus[home.salesProcessStatus] : '-'}`}</Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}
