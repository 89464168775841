import { useMutation, useQuery } from '@apollo/client';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  gridClasses,
  gridDateComparator,
  gridNumberComparator,
} from '@mui/x-data-grid';
import { sv as locale } from '@norban/locale';
import React, { useState } from 'react';

import {
  BofHomeGreetingsCreateHomeMessageDocument,
  BofHomeGreetingsDocument,
  HomeMessageState,
  HomeMessageType,
} from '../../../generated/backend/graphql';

type Props = {
  homeId: string;
};

const HomeGreetings = ({ homeId }: Props) => {
  const L = locale.backoffice;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [message, setMessage] = useState('');
  const { data, error, loading } = useQuery(BofHomeGreetingsDocument, {
    variables: {
      filter: {
        toHomeId: homeId,
        states: [HomeMessageState.Delivered],
      },
    },
  });
  const [sendHomeMessage] = useMutation(
    BofHomeGreetingsCreateHomeMessageDocument,
  );

  const handleSendMessage = async () => {
    await sendHomeMessage({
      variables: {
        input: {
          // Admin user
          fromUserId: null,
          toHomeId: homeId,
          state: HomeMessageState.Pending,
          message,
          type: HomeMessageType.Agent,
        },
      },
    });

    setDialogOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      sortable: true,
      width: 80,
      valueGetter: ({ value }) => value,
      sortComparator: gridNumberComparator,
    },
    {
      field: 'createdAt',
      headerName: L.homeGreetings.created,
      sortable: true,
      width: 150,
      valueGetter: ({ value }) => value,
      renderCell: ({ value }) =>
        value
          ? new Intl.DateTimeFormat('sv-SE', {
              day: 'numeric',
              month: 'short',
              hour: 'numeric',
              minute: 'numeric',
            }).format(new Date(value))
          : '',
      sortComparator: gridDateComparator,
    },
    {
      field: 'fromUserName',
      headerName: L.homeGreetings.sentBy,
      sortable: true,
      width: 200,
      valueGetter: ({ row }) => row.fromUser?.user.name,
    },
    {
      field: 'fromUserId',
      headerName: 'Userid',
      sortable: true,
      width: 120,
      renderCell: ({ value }) => (
        <a
          onClick={e => e.stopPropagation()}
          href={`/users/${value}`}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      ),
      sortComparator: gridNumberComparator,
    },
    {
      field: 'message',
      flex: 1,
      maxWidth: 460,
      minWidth: 150,
      headerName: L.homeGreetings.message,
      sortable: true,
      valueGetter: ({ value }) => value,
    },
  ];

  const rows = data?.homeMessages ?? [];

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{L.errors.general}</Alert>;
  }

  return (
    <Paper>
      <Dialog open={dialogOpen} maxWidth="xs" onClose={() => {}} fullWidth>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6">Skriv hälsning</Typography>
            <TextField
              autoFocus
              fullWidth
              label="Hälsning"
              margin="normal"
              multiline
              rows={8}
              variant="outlined"
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Avbryt</Button>
          <Button
            color="primary"
            variant="contained"
            disabled={message.length === 0}
            onClick={() => handleSendMessage()}
          >
            Skicka
          </Button>
        </DialogActions>
      </Dialog>
      <Box m={2}>
        <Button
          color="secondary"
          size="small"
          variant="contained"
          onClick={() => setDialogOpen(true)}
          startIcon={<WavingHandIcon />}
        >
          Skriv hälsning
        </Button>
      </Box>
      <DataGrid
        autoHeight
        columns={columns}
        getRowHeight={() => 'auto'}
        rows={rows}
        sx={{
          [`& .${gridClasses.cell}`]: {
            alignItems: 'flex-start',
            paddingBottom: '10px',
            paddingTop: '10px',
          },
        }}
        disableRowSelectionOnClick
      />
    </Paper>
  );
};

export default HomeGreetings;
