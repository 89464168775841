import { CardContent } from '@mui/material';
import { sv as locale } from '@norban/locale';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import EmailDialog from '../../components/EmailDialog';
import PageHeader from '../../components/PageHeader';
import QueryError from '../../components/QueryError';
import RootCard from '../../components/RootCard';
import {
  BulkEmailType,
  BulkEmailUnsubscribeType,
} from '../../generated/backend/graphql';
import useCheckedList from '../../hooks/useCheckedList';

import BanUserDialog from './components/BanUserDialog';
import CreateHomeDialog from './components/CreateHomeDialog';
import CreateUserDialog from './components/CreateUserDialog';
import EditUserLabelsDialog from './components/EditUserLabelsDialog';
import UserListHead from './components/UserListHead';
import UserTable from './components/UserTable';
import { PAGE_COUNT } from './config';
import useUserFilterSearchResult from './hooks/useUserFilterSearchResult';

const L = locale.backoffice.userList;

const UserList = () => {
  const [editUserId, setEditUserId] = useState();

  const [createUserOpen, setCreateUserOpen] = useState(false);
  const [banUserOpen, setBanUserOpen] = useState(false);
  const [addHomeOpen, setAddHomeOpen] = useState(false);
  const [sendEmailOpen, setSendEmailOpen] = useState(false);
  const [editUserLabelsOpen, setEditUserLabelsOpen] = useState(false);

  const [page, setPage] = useState(1);

  const {
    dataUsers,
    loadingUsers,
    errorUsers,
    refetchUsers,
    dataRootAreas,
    errorRootAreas,
    loadingRootAreas,
    dataUserLabels,
    userFilter: userFilterVariables,
  } = useUserFilterSearchResult(page);

  const { edges, pageInfo } = dataUsers?.usersPaginated ?? {};
  const totalCount = pageInfo?.totalCount ?? 0;

  const users = useMemo(
    () => edges?.map(edge => edge?.node).filter(Boolean),
    [edges],
  );
  const editUserData = useMemo(
    () => editUserId && users?.find(user => user?.id === editUserId),
    [editUserId, users],
  );

  const rootAreas = dataRootAreas?.rootAreas;
  const userLabels = dataUserLabels?.userLabels;

  const userFilter = useSelector(state => state.userFilter);

  // Checkboxes
  const { checkedList, toggle, toggleAll, clearAll, isChecked, isAllChecked } =
    useCheckedList(users?.map(u => ({ ...u, userId: u.id })) ?? []);

  const headerButtons = [
    { text: L.addUser, onClick: () => setCreateUserOpen(true) },
    { text: L.banUser, onClick: () => setBanUserOpen(true) },
    { text: L.addHome, onClick: () => setAddHomeOpen(true) },
    {
      text: L.sendEmailSelected,
      onClick: () => {
        setSendEmailOpen(true);
      },
    },
  ];

  return (
    <>
      {(errorUsers || errorRootAreas) && (
        <QueryError error={errorUsers || errorRootAreas} data={dataUsers} />
      )}
      {!errorUsers && !errorRootAreas && (
        <>
          <CreateUserDialog
            open={createUserOpen}
            onClose={() => setCreateUserOpen(false)}
          />
          <BanUserDialog
            open={banUserOpen}
            onClose={() => setBanUserOpen(false)}
          />
          <CreateHomeDialog
            open={addHomeOpen}
            onClose={() => setAddHomeOpen(false)}
          />
          <EmailDialog
            defaultType={BulkEmailType.MarketingSoldWithPrice}
            options={[
              {
                type: BulkEmailType.MarketingSoldWithPrice,
                unsubscribeType: BulkEmailUnsubscribeType.Marketing,
                description:
                  locale.backoffice.emailDialog.soldWithPriceDescription,
                message: locale.backoffice.emailDialog.soldMessage,
                signatureType: 'withoutLogo',
              },
              {
                type: BulkEmailType.MarketingSoldWithoutPrice,
                unsubscribeType: BulkEmailUnsubscribeType.Marketing,
                description:
                  locale.backoffice.emailDialog.soldWithoutPriceDescription,
                message: locale.backoffice.emailDialog.soldMessage,
                signatureType: 'withoutLogo',
              },
              {
                type: BulkEmailType.HiddenReactivation,
                unsubscribeType: BulkEmailUnsubscribeType.Marketing,
                description:
                  locale.backoffice.emailDialog.hiddenReactivationDescription,
                message:
                  locale.backoffice.emailDialog.hiddenReactivationMarMessage,
                signatureType: 'withoutLogo',
              },
              {
                type: BulkEmailType.Custom,
                unsubscribeType: BulkEmailUnsubscribeType.Marketing,
                description: locale.backoffice.emailDialog.customDescription,
                signatureType: 'withoutLogo',
              },
            ]}
            recipients={checkedList}
            userFilter={userFilterVariables}
            userFilterCount={totalCount}
            open={sendEmailOpen}
            onSent={() => {}}
            onClose={() => setSendEmailOpen(false)}
          />
          <EditUserLabelsDialog
            open={editUserLabelsOpen}
            onClose={updated => {
              setEditUserLabelsOpen(false);
              if (updated) {
                refetchUsers();
              }
            }}
            userId={editUserId}
            filterUserLabels={userFilter.filterUserLabels}
            userAssociatedLabels={editUserData?.userLabelIds}
            userLabelIds={userLabels}
          />
          <PageHeader
            title={L.users}
            buttons={undefined}
            links={undefined}
            overline={undefined}
          />
          <RootCard>
            <CardContent>
              <UserListHead buttons={headerButtons} />
            </CardContent>
            <UserTable
              loading={loadingUsers || loadingRootAreas}
              checkboxes={{
                toggle,
                toggleAll,
                clearAll,
                isChecked,
                isAllChecked,
              }}
              onChangePage={newPage => {
                if (newPage !== page) {
                  setPage(newPage);
                }
              }}
              onChangeUser={() =>
                // FIXME - don't fetch all users, just the one that's updated!
                refetchUsers({
                  last: PAGE_COUNT,
                  offset: (page - 1) * PAGE_COUNT,
                })
              }
              onEditUserLabels={id => {
                setEditUserId(id);
                setEditUserLabelsOpen(true);
              }}
              onFilterUsers={() => setPage(1)}
              page={page}
              rootAreas={rootAreas}
              totalCount={totalCount}
              userCount={users?.length || 0}
              userFilter={userFilter}
              userLabels={userLabels}
              users={users}
            />
          </RootCard>
        </>
      )}
    </>
  );
};

export default UserList;
