import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { sv as locale } from '@norban/locale';
import cx from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import EmailDialog from '../../../components/EmailDialog';
import {
  BofFollowersTableFollowerFragment,
  BulkEmailType,
  BulkEmailUnsubscribeType,
} from '../../../generated/backend/graphql';
import useCheckedList from '../../../hooks/useCheckedList';
import { defaultState } from '../../../reducers/followerFilter';
import { blueLight2, offBlack, tableRowHoverColor } from '../../../theme';

export type FollowerFilterType = typeof defaultState & {
  active?: boolean;
};

const useStyles = makeStyles({
  userLink: {
    color: blueLight2,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  primaryTextColor: {
    color: offBlack,
  },
  row: {
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: tableRowHoverColor,
    },
  },
  rowInactive: {
    opacity: 0.5,
  },
});

type Props = {
  followers: BofFollowersTableFollowerFragment[];
  followersError?: boolean;
  followersLoading?: boolean;
  filter: FollowerFilterType;
};

const FollowersTable = ({
  followers,
  followersError = false,
  followersLoading = false,
  filter,
}: Props): React.JSX.Element => {
  const L = locale.backoffice.allFollowers;
  const fmt = locale.backoffice.generic.dateAndTimeFormat;
  const styles = useStyles('');
  const [dialogOpen, setDialogOpen] = useState<'email' | 'add_follower'>();

  const { checkedList, toggle, toggleAll, clearAll, isChecked, isAllChecked } =
    useCheckedList<BofFollowersTableFollowerFragment>(followers);

  const emailUsers = [
    ...new Map(checkedList.map(({ user }) => [user?.id, user])).values(),
  ].reduce(
    (acc, user) => {
      const id = user?.id;
      const email = user?.email;

      if (!id || !email) {
        return acc;
      }

      return [...acc, { id, email }];
    },
    [] as { id: string; email: string }[],
  );

  const handleFollowerClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    userId: string | null | undefined,
  ) => {
    e.preventDefault();
    if (!userId) {
      return;
    }

    window.open(`/users/${userId}/followings`, '_blank', 'noreferrer');
  };

  if (followersError) {
    return <Alert severity="error">{locale.backoffice.errors.general}</Alert>;
  }

  return (
    <Paper>
      <EmailDialog
        defaultType={BulkEmailType.Custom}
        recipients={emailUsers}
        userFilter={{
          homeFilter: {
            homeStates: filter.homeStates,
          },
          // FIXME - more filters needed
        }}
        userFilterCount={-1}
        open={dialogOpen === 'email'}
        onSent={() => {}}
        onClose={() => setDialogOpen(undefined)}
        options={[
          {
            type: BulkEmailType.FollowerViewingScheduled,
            unsubscribeType: BulkEmailUnsubscribeType.Marketing,
            description: 'Visning planerad',
            message: locale.backoffice.emailDialog.viewingPlannedMessage,
            signatureType: 'withoutLogo',
          },
          {
            type: BulkEmailType.FollowerRecommendAHome,
            unsubscribeType: BulkEmailUnsubscribeType.Marketing,
            description: 'Tips om bostad',
            message: locale.backoffice.emailDialog.recommendAHomeMessage,
            signatureType: 'withoutLogo',
          },
          {
            type: BulkEmailType.FollowerCustomWithHome,
            unsubscribeType: BulkEmailUnsubscribeType.Marketing,
            description: 'Skriv själv med bostadskort',
            signatureType: 'withoutLogo',
          },
          {
            type: BulkEmailType.Custom,
            unsubscribeType: BulkEmailUnsubscribeType.Marketing,
            description: locale.backoffice.emailDialog.customDescription,
            signatureType: 'withoutLogo',
          },
        ]}
      />
      <Box ml={2}>
        <Button
          color="secondary"
          disabled={checkedList.length === 0}
          size="small"
          variant="contained"
          onClick={() => setDialogOpen('email')}
          startIcon={<EmailIcon />}
        >
          Skicka mejl
        </Button>
      </Box>
      <Table size="small" aria-label="home follower table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={isAllChecked()}
                disabled={followers.length === 0}
                onChange={e => {
                  if (e.target.checked) {
                    toggleAll();
                  } else {
                    clearAll();
                  }
                }}
              />
            </TableCell>
            <TableCell>{L.follows}</TableCell>
            <TableCell>{L.updated}</TableCell>
            <TableCell>{L.name}</TableCell>
            <TableCell>{L.userId}</TableCell>
            <TableCell>{L.email}</TableCell>
            <TableCell>{L.phone}</TableCell>
            <TableCell>{L.hasHome}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {followersLoading && <CircularProgress />}
          {followers.map(follower => {
            const { active, user, id, updatedAt, userId } = follower ?? {};

            const { email, name, phone } = user ?? {
              email: null,
              name: null,
              phone: null,
            };

            return (
              <TableRow
                key={id}
                className={`${styles.row}${
                  active ? '' : ` ${styles.rowInactive}`
                }`}
                onClick={e => handleFollowerClick(e, userId)}
              >
                <TableCell
                  onClick={e => {
                    e.stopPropagation();
                    toggle(follower);
                  }}
                >
                  <Checkbox checked={isChecked(follower)} />
                </TableCell>
                <TableCell>
                  <RouterLink
                    className={styles.userLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    to={`/homes/${follower.homeId}`}
                    onClick={e => e.stopPropagation()}
                  >
                    {follower.homeId}
                  </RouterLink>
                </TableCell>
                <TableCell>{moment(updatedAt).format(fmt)}</TableCell>
                <TableCell>{name || '-'}</TableCell>
                <TableCell>
                  <RouterLink
                    className={styles.userLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    to={`/users/${userId}`}
                    onClick={e => e.stopPropagation()}
                  >
                    {userId || '-'}
                  </RouterLink>
                </TableCell>
                <TableCell>
                  <Link
                    className={cx(!email && styles.primaryTextColor)}
                    href={(email && `mailto:${email}`) ?? undefined}
                    underline="hover"
                    onClick={e => e.stopPropagation()}
                  >
                    {email || '-'}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    className={cx(!phone && styles.primaryTextColor)}
                    href={(phone && `tel:${phone}`) ?? undefined}
                    underline="hover"
                  >
                    {phone || '-'}
                  </Link>
                </TableCell>
                <TableCell>
                  {follower.user?.home?.id && (
                    <IconButton
                      aria-label="home"
                      component={RouterLink}
                      to={`/homes/${follower.user?.home?.id}`}
                      size="large"
                    >
                      <HomeIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default FollowersTable;
